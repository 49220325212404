.progressContainer {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.titleIcon {
  width: 26.75px;
  height: 18.62px;
  margin-right: 8px;
}

.addIcon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.creditCardIcon {
  margin-left: 4px;
}

.defaultBox {
  display: none;
}

.defaultPayment {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  opacity: 1;
}

.customButton{
  width: 280px;
}

.expireDateBox{
  display: none;
}

.editLabelPaymentMethod {
  margin-left: 8px !important;
  display: none;
  cursor: pointer;
}

.editAction{
  display: flex;
  align-items: center;
}

.paymentDetails{
  width: 225px;
}

.cardTypeText {
  margin-left: 8px;
}

.lastDigitsText {
  margin-left: 8px;
}

  /*
   * Tablet resolution
   */
   @media (min-width: 688px) {

    .creditCardIcon {
      margin-left: 16px;
    }

    .defaultBox {
      display: block;
      margin-left: 32px;
      background: rgb(205, 222, 242, 0.58);
      border-radius: 10px;
      width: 80px;
    }

    .expireDateBox{
      display: block;
      margin-left: 32px;
      width: 110px;
    }

    .cardTypeText {
      margin-left: 16px;
      width: 100px;
      display: flex;
    }

    .lastDigitsText {
      margin-left: 0px;
    }

    .editAction{
      margin-left: 24px;
    }

    .editLabelPaymentMethod {
      display: block;
    }

    .deleteAction{
      margin-left: 16px;
    }

    .paymentDetails{
      width: 272px;
    }
  }

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {
  .defaultBox {
    margin-left: 104px;
  }

  .expireDateBox{
    margin-left: 104px;
  }

  .editAction{
    margin-left: 40px;
  }
}