.scheduledScanOptionsDialog {
  border-radius: 14px;
}

.scheduledScanOptionsDialogContent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 350px;
  margin: 0 auto;
  padding-bottom: 18px;
}

.controlContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.titleContainer {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 20px;
}

.titleIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.frequencyContainer {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.frequencyContainer .frequencyRadioGroup {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

.frequencySelectContainer {
  display: none;
}

.dayAndTimeContainer {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid #bdbdbd;
  align-items: center;
}

.timeContainer .timeInput {
  margin-top: 16px;
}

.saveButton,
.rescanButton {
  width: 255px;
}

.saveButton {
  margin: 18px 0;
}

@media (max-width: 767px) {
  .title {
    font-weight: 600;
  }

  .scheduledScanOptionsDialogContent {
    width: 285px;
  }

  .frequencyContainer .frequencyRadioGroup {
    display: none;
  }

  .dayAndTimeContainer {
    flex-direction: column;
    width: 100%;
  }

  .frequencySelectContainer {
    display: inline-block;
  }

  .dayContainer {
    width: 100%;
    margin-bottom: 18px;
  }

  .timeContainer {
    width: 100%;
  }

  .dayOfMonthInput,
  .timeInput {
    width: 100%;
  }
}