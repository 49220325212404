.container {
  width: 100%;
  margin: auto;
  padding-top: 8px;
  position: relative;
}

.content {
  margin: 24px 56px 32px;
  align-items: center;
}

.customButton {
  width: 240px;
}

.cancelButtonText {
  color: #535a6a;
}

  /*
   * To avoid collapse the button with the div for small sizes 
   */
   @media (max-width: 310px) {
    .customButton {
      width: 90%;
    }
  }
