.cleanupCompleteDialog {
    border-radius: 20px;
    padding: 14px 24px 24px;
    width: 619px;
}

.cleanupCompleteDialogContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
}

.headerImage {
    width: 109px;
    height: 78px;
    margin-bottom: 6px;
}

.viewLockedFiles {
    cursor: pointer;
}

.categoriesContainer {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 28px;
}

.cleanupCategory {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}

.cleanupCategory:nth-child(odd) {
    background-color: #F8F8F8;
    border-radius: 12px;
}
.categoryName {
    color: #007DBA;
    flex-basis: 50%;
}

.categorySizeContainer {
    flex-basis: 50%;
    text-align: right;
}

.categorySize {
    color: #39B54A;
}

.categoryCheck {
    margin-left: 12px;
    width: 17px;
    height: 12px;
}

.closeButton {
    width: 233px;
    height: 44px;
    margin-bottom: 24px;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.spaceFreedContainer {
    display: flex;
    flex-basis: 50%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
}

.spaceFreedCheck {
    width: 17px;
    height: 12px;
    margin-right: 10px;
    margin-bottom: 8px;
}

.spaceFreedText {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.mobile {
    display: none;
}

@media (max-width: 767px) {
    .cleanupCompleteDialog {
        padding: 14px 8px 24px;
        width: unset;
    }

    .cleanupCategory {
        padding: 15px 13px;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: inherit;
    }

    .closeButton {
        margin: 0 auto;
    }
}