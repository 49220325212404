
.cancelButtonText {
  color: #535a6a;
}

.customButton {
  width: 270px;
}

.cancelButton {
  width: 270px;
  border: 1px solid #DBDBDB !important;
  order: 1;
  margin-top: 24px;
}

.coverMembersText {
  margin-left: 12px !important;
}

.coveredTeamMembers {
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  width: 90%;
  max-width: 976px;
  padding: 20px 30px 20px 30px;
}

.userBoxContainer{
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}

.userBox {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  flex-basis: 100%;
  max-width: 258px;
  margin-top: 20px;
  padding: 36px 0px 28px 0px;
}

.name {
  font-weight: 600;
}

.keepServiceButton {
  margin-left: 0px;
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {

  .cancelButton {
    width: 270px;
    border: 1px solid #DBDBDB !important;
    order: 0;
    margin-top: 0px;
  }

  .coveredTeamMembers {
    padding: 38px 48px 20px 38px;
  }

  .userBoxContainer{
    justify-content: space-between;
  }

  .userBox {
    flex-basis: 50%;
  }

  .keepServiceButton {
    margin-left: 20px;
  }

}

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {

  .featuresContainer {
    flex-basis: 50%;
    padding-left: 0px;
  }

  .coveredTeamMembers {
    padding: 38px 26px 20px 26px;
  }

  .userBox {
    flex-basis: 33.3%;
  }

}
