.progressContainer {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.titleIcon {
  width: 26.75px;
  height: 18.62px;
  margin-right: 8px;
}

.creditCardIcon {
  margin-right: 12px;
}

.customButton {
  width: 90%;
  max-width: 280px;
}

.resumeButton {
  width: 90%;
  max-width: 280px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
}

.cancelButtonText {
  color: #535a6a;
}

.customButton {
  width: 270px;
}

.cancelButton {
  width: 270px;
  border: 1px solid #DBDBDB !important;
  order: 1;
  margin-top: 24px;
}

.featuresContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 16px;
}

.disabled {
  opacity: 0.5;
}

.overlay {
  position: absolute;
  width: 240px;
  height: 80px;
  margin-top: -60px;
}

.planTypeContainer {
  margin-top: 24px;
}

.purchaseSummaryText {
  margin-top: 8px;
}

.billingCycleTextContainer {
  margin-top: 8px
}

.billingCycleContainer {
  margin-top: 24px;
}

.quantityInfoContainer {
  margin-top: 0px;
}

.dividerTablet {
  display: none;
}

.billingSection {
  margin-top: 40px;
  flex-basis: 100%;
}

.billingCycleTextContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.editActionBillingCard {
  flex: 20%;
  text-align: end;
}

.editLabel {
  margin-left: 8px !important;
  cursor: pointer;
}

.lastDigitsText {
  margin-left: 5px;
}

.detailsIcon {
  margin-left: -18px;
  margin-right: 6px;
}

.planBoldText {
  margin-right: 5px;
  margin-left: 5px;
}

.billedMonthlyText {
  font-size: 12px;
  font-weight: 600;
}

.detailsIconBottom {
  margin-top: -36px;
  margin-left: -20px;
  margin-right: 5px;
}

.checkMarkText {
  margin-left: 5px;
}

.billingCycleTextContainer {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.messagePlanDetails {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 14px;
  padding: 16px 16px 16px 16px;
  width: 100%;
  margin-top: 24px;
}

.canceledIndicator {
  max-width: 104px;
  background: #CDDEF2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #007DBA;
  font-weight: 600;
  padding: 3px 26px 3px 26px;
  font-size: 12px;
  margin-left: 40px;
}

.cancelPlanMobile {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 14px;
  display: block;
  padding: 20px 12px 12px 12px;
}

.cancelPlanTablet {
  display: none;
}



/*
   * Tablet resolution
   */
@media (min-width: 688px) {

  .cancelPlanMobile {
    display: none;
  }

  .cancelPlanTablet {
    display: block;
  }

  .messagePlanDetails {
    width: 100%;
    background-color: white;
    border-radius: 0px;
    padding: 0px;
    width: 100%;
    margin-top: 0px;
  }

  .cancelButton {
    width: 270px;
    border: 1px solid #DBDBDB !important;
    order: 0;
    margin-top: 0px;
  }

  .resumeButton {
    margin-top: 104px;
  }

  .featuresContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 24px;
  }

  .billingCycleContainer {
    flex-basis: 50%;
    margin-top: 0px;
  }

  .planTypeContainer {
    margin-top: 8px;
    flex-basis: 50%;
  }
  
  .purchaseSummaryText {
    margin-top: 8px;
  }

  .billingCycleTextContainer {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  .quantityInfoContainer {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 14px;
    padding: 16px 32px 16px 32px;
    width: 100%;
    margin-top: 24px;
  }

  .dividerTablet {
    display: block;
  }

  .billingSection {
    flex-basis: 100%;
    margin-top: 0px;
  }
  
  .editAction {
    flex: 50%;
    text-align: end;
  }

  .detailsIcon {
    margin-left: -20px;
    margin-right: 8px;
  }

  .detailsIconBottom {
    margin-top: -18px;
    margin-left: -20px;
    margin-right: 5px;
  }


}

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {

  .featuresContainer {
    flex-basis: 50%;
    padding-left: 0px;
  }

  .billingCycleContainer {
    flex-basis: 100%;
  }

  .planTypeContainer {
    flex-basis: 100%;
  }

  .purchaseSummaryText {
    flex-basis: 100%;
  }

  .planTypeTitle {
    margin-top: 32px;
  }

  .billingSection {
    flex-basis: 100%;
    margin-top: 0px;
  }

  .purchaseSummaryTitleContainer {
    margin-top: 0px;
  }

  .quantityInfoContainer {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 14px;
    padding: 16px 32px 16px 32px;
    width: 90%;
  }

  .dividerTablet {
    display: none;
  }

}
