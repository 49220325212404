
.header {
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  color: #ffffff;
  text-align: center;
}

.headerBackground {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 376px;
  margin-top: -61px;
  overflow: hidden;
}

.headerBackground img {
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
}

.header .headerTitle {
  font-weight: normal;
  margin-top: 20px;
}

.titleEmphasis {
  font-weight: bold;
}

.body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 95%;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
}

.helpForm {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.helpForm > div {
  margin: 15px 0;
}

.submitButton {
  width: 200px;
  height: 44px;
  align-self: center;
  margin-top: 25px;
}

.footer {
  background-color: #F5F6F9;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 80px; 
  padding: 20px 30px;
  width: 100%;
}

.footer .footerTitle {
  font-weight: bold;
}

.spacer {
  flex-grow: 1;
}

.contactButton {
  width: 200px;
  height: 44px;
}

.phoneImage {
  width: 82px;
  height: 82px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .body {
    max-width: 100%;
    text-align: center;
  }

  .footer {
    flex-direction: column;
    margin-top: 40px;
  }

  .phoneImage {
    margin-right: 0;
  }

  .contactButton {
    margin-top: 30px;
  }
}