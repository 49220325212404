.cleaningUpDialog {
    border-radius: 20px;
}

.cleaningUpDialogContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.headerImage {
    width: 108px;
    height: 78px;
    margin-bottom: 12px;
}

.ellipsis {
    display: inline-block;
    width: 1em;
}