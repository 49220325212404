.submitButton{
    height: 44px;
    width: 255px;
    align-self: flex-end;
    margin: 10px;
    margin-top: 30px;
}

@media (max-width: 360px) {
    .submitButton {
      margin-top:10px
    }
}

.disabledSubmitButton{
    composes: submitButton;
    opacity: 0.2;
}