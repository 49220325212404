.container {
  width: 100%;
  max-width: 1040px;
  height: 203px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  margin-top: 60px;
  padding: 0px 25px;
}

.tealBackground {
  position: absolute;
  max-width: 1040px;
}

.stage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stageImage {
  height: 64px;
}

.stageLabel {
  width: 90px;
  text-align: center;
  word-spacing: 90px;
  color: #ffffff;
}

.greenCheck {
  position: absolute;
  width: 19px;
  right: 20px;
  top: 0px;
}

.separatorContainer {
  width: 70px;
  position: relative;
  height: 2px;
  background-color: #e9e9f09e;
  border-radius: 4px;
  margin-bottom: 30px;
}

.separatorProgress {
  position: absolute;
  height: 2px;
  border-radius: 4px;
  background-color: #ffffff;
  transition: 0.5s width;
}

@media (max-width: 768px) {
  .stageImage {
    width: 60px;
  }

  .stageLabel {
    width: 70px;
    word-spacing: 70px;
  }

  .greenCheck {
    width: 17px;
    right: 10px;
    top: 2px;
  }

  .separatorContainer {
    width: 50px;
  }
}

@media (max-width: 400px) {
  .container {
    flex-direction: column;
    height: 100%;
    max-width: 321px;
    padding: 25px;
    padding-left: 0;
    align-items: flex-start;
  }

  .tealBackground {
    position: absolute;
    max-width: 410px;
  }

  .stage {
    flex-direction: row;
    width: 100%;
    max-width: 321px;
    margin-left: 20px;
    align-items: center;
  }

  .stageImage {
    width: 64px;
    display: inline;
    margin-right: 5px;
  }

  .stageLabel {
    width: 100%;
    word-spacing: normal;
    text-align: left;
  }

  .greenCheck {
    left: 38px;
    top: 0px;
  }

  .separatorContainer {
    transform: rotate(90deg);
    width: 30px;
    margin-bottom: 14px;
    margin-left: 17px;
    margin-top: 10px;
  }
}

@media (min-width: 500px) {
  .container, .tealBackground {
    max-width: 620px;
  }
}


@media (min-width: 768px) {
  .container, .tealBackground {
    max-width: 687px;
  }
}

@media (min-width: 1024px) {
  .container, .tealBackground {
    max-width: 905px;
  }
}