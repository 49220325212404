.dnsProtection {
    background-color: #F5F6F9;
    padding: 26px 57px 0 68px;
    max-width: 1075px;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.headerImage {
    width: 120px;
    height: 88px;
}

.headerTextContainer {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 35px;
}

.headerTitle {
    color: #007DBA;
    font-size: 24px;
    line-height: 35px;
    font-weight: 600;
}

.headerDescription {
    color: #757E95;
    font-size: 16px;
    line-height: 24px;
}

.body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bodyTitle {
    color: #007DBA;
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
    margin-top: 8px;
}

.bodyDescription {
    color: #757E95;
    font-size: 16px;
    line-height: 24px;
    max-width: 644px;
}

.benefitList {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #FAFAFA;
    border-radius: 14px;
    max-width: 899px;
    justify-content: space-between;
    padding: 38px;
    margin-top: 23px;
    width: 100%;
}

.benefit {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.benefitImage {
    width: 82px;
    height: 88px;
}

.benefitText {
    color: #535A6A;
    font-size: 16px;
    line-height: 22px;
}

.explanationTitle {
    color: #007DBA;
    font-size: 24px;
    line-height: 33px;
    margin-top: 30px;
}

.explanation {
    color: #757E95;
    font-size: 16px;
    line-height: 24px;
    text-align: initial;
    margin-top: 25px;
}

.footer {
    align-self: flex-end;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 25px;
    margin-top: 20px;
    position: relative;
}

.infoImage {
    width: 14px;
    height: 14px;
    margin-right: 14px;
}

.footerText {
    color: #757E95;
    font-size: 18px;
    line-height: 24px;
}

.quad9Image {
    width: 72px;
    height: 50px;
    margin-left: 18px;
}

.mobileHeader {
    display: none;
}

.quad9InfoContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.quad9HoverContainer {
    position: absolute;
    width: 631px;
    top: -198px;
    left: -431px;
    box-shadow: 0px 4px 12px #00000026;
    border: 1px solid #00000000;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 21px 31px;
}


@media (max-width: 767px) {
    .dnsProtection {
        padding: 0;
    }

    .header {
        display: none;
    }

    .mobileHeader {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        text-align: start;
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 28px;
        margin-bottom: 35px;
    }

    .headerImage {
        width: 64px;
        height: 46px;
    }

    .headerTextContainer {
        margin: 0 0 0 16px;
    }

    .headerTitle {
        font-size: 18px;
    }

    .switch {
        margin-top: 26px;
    }

    .body {
        padding: 18px;
    }

    .bodyTitle {
        font-size: 18px;
    }

    .benefitList {
        flex-direction: column;
        padding: 13px;
    }

    .benefit {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: initial;
    }

    .benefitImage {
        width: 68px;
        height: 74px;
    }

    .explanationTitle {
        font-size: 18px;
    }

    .footer {
        align-self: unset;
        margin-right: 0;
    }
}