.container {
  max-width: 1200px;
  height: 920px;
}

.content {
  width: 920px;
  height: 712px;
  background: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.185);
}

.addTeamIllustration {
  max-width: 384px;
}

.customButton {
  width: 264px;
  max-width: 80%;
  height: 48px;
}

  /*
   * Mobile resolution 
   */
@media (max-width: 767px) {
  .container {
    max-width: 672px;
    max-height: 720px;
  }

  .content {
    max-width: 90%;
    max-height: 90%;
  }

  .addTeamIllustration {
    width: 85%;
  }
}

/*
   * Tablet vertical
   */
@media (min-width: 768px) and (max-width: 1024px) and (min-height: 769px) {
  .container {
    max-width: 1024px;
    max-height: 1024px;
  }

  .content {
    max-width: 85%;
    max-height: 85%;
  }
}

/*
   * Tablet horizontal / Laptop
   */
@media (min-width: 1024px) and (max-width: 1200px) {
  .content {
    width: 80%;
  }
}
