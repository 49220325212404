.fileListDialog {
    border-radius: 20px;
    padding: 14px 24px 24px;
}

.fileListDialogContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
}

.headerImage {
    width: 112px;
    height: 97px;
    margin-bottom: 13px;
}

.analysisContainer {
    border-radius: 12px;
    width: 100%;
    margin-bottom: 46px;
}

.fileList {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 38px;
    max-height: 300px;
    overflow: auto;
}

.fileListEntry {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    text-align: start;
}

.fileListEntry:nth-child(odd) {
    background-color: #F8F8F8;
    border-radius: 12px;
}

.fileName {
    line-height: 60px;
    color: #007DBA;
    cursor: pointer;
    text-align: left;
}

.cleanButton {
    width: 195px;
    height: 44px;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 17px;
}

@media (max-width: 767px) {
    .fileListDialog {
        padding: 14px 8px 24px;
    }
    
    .fileListEntry {
        padding: 15px 13px;
    }
}