.dashboard {
    position: relative;
    top: -25px;
}

.dashboardHeaderCarousel .carouselItemTitle {
    font-weight: normal;
    margin-bottom: 10px;
}

.dashboardHeaderCarousel .carouselItemDescription {
    display: inline-block;
    max-width: 80%;
    align-self: center;
}

.welcomeSolveIq {
    display: block;
    margin: 0 auto;
    font-weight: bold;
}

.dashboard .needsAttention,
.taskNumber.needsAttention,
.ticketNumber.needsAttention {
    color: #f75f34;
}

.bbbTitle {
    color: #007DBA;
}

.dashboardHeader {
    width: 100%;
}

.dashboardHeaderContent {
    margin: 30px 50px;
    text-align: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
}

.dashboardHeaderBackgroundContainer {
    position: absolute;
    top:0;
    z-index: -1;
    width: 100%;
    overflow: hidden;
}

.dashboardHeaderBackground {
    min-width: 100%;
    min-height: 300px;
}

.dashboardHeaderMobileBackgroundContainer {
    display: none;
    position: absolute;
    top:0;
    z-index: -1;
    width: 100%;
    overflow: hidden;
}

.dashboardHeaderMobileBackground {
    min-width: 100%;
}

.dashboardHeaderCarousel {
    display: inline-block;
    flex: 1;
    margin-top: 30px;
    max-width: 580px;
}

.dashboardHeaderImageContainer {
    flex: 0;
}

.dashboardHeaderImage {
    width: 261px;
    height: 271px;
}

.dashboardBody {
    text-align: center;
}

.normalBodyTitle .dashboardBodyTitle {
    display: block;
    font-size: 21px;
    line-height: 24px;
}

.dashboardBodyTitleItemNumber {
    color: #0fac21;
    font-weight: 600;
}

.dashboardItems {
    display: flex;
    flex-direction: row;
}

.taskNumber,
.ticketNumber {
    font-weight: 600;
}

.mobileBodyTitle {
    display: none;
    color: #ffffff;
}

.mobileBodyTitle .dashboardBodyTitleItemNumber.needsAttention {
    color: #ffffff;
}

.tasksDescription {
    font-size: 18px;
}

.ticketsDescription {
    font-size: 18px;
}

@media (min-width: 768px) {
    .dashboard .bigBoxButton .bigBoxButtonEmblem {
        width: 42px;
        height: 42px;
        position: absolute;
        top: -20px;
        left: 50px;
    }

    .dashboard .bigBoxButton .bigBoxButtonEmblemText {
        color: #ffffff;
        width: 42px;
        height: 42px;
        line-height: 42px;
        position: absolute;
        top: -20px;
        left: 50px;
        font-weight: 600;
    }
}

@media (max-width: 1024px) and (max-height: 768px) {
    .dashboardBody {
        margin-top: 0;
    }

    .dashboardHeaderContent {
        margin: 0 auto;
    }
}

@media (max-width: 1023px) {
    .dashboardHeaderImage {
        width: 225px;
        height: 235px;
    }
}

@media (max-width: 767px) {
    .dashboard .dashboardBody .dashboardItems .bbbTitle {
        font-size: 16px;
        line-height: 22px;
    }

    .dashboardHeaderImageContainer {
        display: none;
    }

    .dashboardItems {
        flex-direction: column;
    }

    .mobileBodyTitle {
        display: inline-block;
        margin-top: 20px;
    }

    .normalBodyTitle {
        display: none;
    }

    .dashboardHeaderBackgroundContainer {
        display: none;
    }

    .dashboardHeaderMobileBackgroundContainer {
        display: flex;
    }

    .dashboardHeaderContent {
        flex-direction: column;
        padding: 20px
    }

    .dashboardHeaderCarousel {
        margin-top: 0;
    }

    .dashboardBodyTitleItemNumber {
        color: #ffffff;
        font-weight: bolder;
    }

    .tasksDescription {
        font-size: 14px;
    }
}
