.container {
  border-radius: 20px;
  width: 320px;
  background-color: white;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.content {
  margin: 16px 24px 56px 24px;
  align-items: center;
}

.closeIconContainer {
  margin-bottom: 32px;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  min-width: 50px;
  min-height: 50px;
}

.icon {
  display: block;
  margin: auto;
  animation: jump 1.2s;
}

.cancelButtonText {
  color: #535a6a;
}

.customButton {
  width: 256px;
  max-width: 90%;
  outline: none;
}

.paymentContainer {
  text-align: "center";
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 8px;
  height: 72px;
}

.creditCardIcon {
  margin-left: 4px;
}

.cardTypeText {
  margin-left: 8px;
}

.lastDigitsText {
  margin-left: 8px;
}

.defaultPayment {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  opacity: 1;
}

.paymentMethodsContainer {
  max-height: 216px;
  overflow-y: auto;
}

  /*
   * Tablet resolution
   */
@media (min-width: 768px) {
  .container {
    width: 516px;
  }

  .content {
    margin-left: 40px;
    margin-right: 40px;
  }

  .paymentContainer {
    text-align: "center";
    width: 100%;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 20px 32px 20px 20px;
  }

  .creditCardIcon {
    margin-left: 16px;
  }

  .cardTypeText {
    margin-left: 16px;
    width: 100px;
    display: flex;
  }

  .lastDigitsText {
    margin-left: 0px;
  }
}
