.container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
  border-radius: 14px;
  border-radius: 14px;
  background-color: white;
}

.content {
  margin: 16px 16px 0px 16px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.logoImage {
  height: 24px;
  width: 25px;
}

.settingsText {
  cursor: pointer;
  font-weight: 600;
}

