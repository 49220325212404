.container {
  border-radius: 16px;
  background-color: white;
  outline: 0;
}

.content {
  margin: 24px 24px 0px 24px;
  align-items: center;
}

.closeIconContainer {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  min-width: 50px;
  min-height: 50px;
}

.subtitle {
  color: rgb(94, 102, 123, 0.7);
}

.horizontalBar,
.horizontalBarContainer {
  display: none;
}

.error {
  color: #e31d2b;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 220px;
  width: calc(100% + 5px);
  margin-left: 5px;
  padding-right: 5px;
}

.inputContainer::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

.inputContainer::-webkit-scrollbar {
  width: 10px;
  background-color: #f4f4f4;
}

.inputContainer::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #0fb4d1;
}

.addTeamIcon {
  margin-right: 4px;
  margin-left: 4px;
  text-align: center;
  vertical-align: middle;
}

.menuTeamIcon {
  vertical-align: middle;
  margin-right: 8px;
}

.addNewMemberButton {
  cursor: pointer;
}

.customButton {
  width: 90%;
  max-width: 256px;
}

.progressContainer {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.buttonContainer {
  text-align: center;
}

.tittleAddTeamMembers {
  letter-spacing: 0.3px;
  color: #535A6A !important;
  font-weight: 600;
}

  /*
   * Tablet resolution
   */
@media (min-width: 688px) {

  .content {
    margin: 24px 48px 0px 40px;
  }

  .horizontalBarContainer {
    display: block;
    width: 100%;
  }

  .horizontalBar {
    display: block;
  }

  .inputContainer {
    height: 232px;
    width: calc(100% + 16px);
    padding-right: 16px;
  }

  .buttonContainer {
    margin-top: 56px;
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-end;
    text-align: end;
  }
}

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {
  .content {
    margin: 24px 96px 0px 96px;
  }
}
