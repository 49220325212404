.navLink {
  text-align: center;
  text-decoration: none;
  color: #757e95;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  position: relative;
}

.activeNavLink {
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #535a6a;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  margin: 0 20px;
  position: relative;
}

.navLink--bottom--hover:hover {
  margin-top: 2px;
  border-bottom: 2px solid #65d9c8;
}

.navLink--bottom {
  composes: navLink navLink--bottom--hover;
}

.activeNavLink--bottom {
  composes: activeNavLink;
  border-bottom: 2px solid #65d9c8;
  margin-top: 2px;
}

.navLink--top--hover:hover {
  border-top: 2px solid #65d9c8;
}

.navLink--top {
  composes: navLink navLink--top--hover;
}

.activeNavLink--top {
  composes: activeNavLink;
  border-top: 2px solid #65d9c8;
}

.navLink--left--hover:hover {
  box-shadow: inset 2px 0px 0px 0px #65d9c8;
}

.navLink--left {
  composes: navLink navLink--left--hover;
  height: 40px;
  width: 100%;
  margin: 20px 0;
  padding: 0 10px;
}

.activeNavLink--left {
  composes: activeNavLink;
  height: 40px;
  width: 100%;
  box-shadow: inset 2px 0px 0px 0px #65d9c8;
  padding: 0 10px;
  margin: 20px 0;
}

.navLink--right--hover:hover {
  border-right: 2px solid #65d9c8;
}

.navLink--right {
  composes: navLink navLink--right--hover;
  height: 40px;
  width: 100%;
  margin: 20px 0;
}

.activeNavLink--right {
  composes: activeNavLink;
  height: 40px;
  width: 100%;
  border-right: 2px solid #65d9c8;
  margin: 20px 0;
}

.menuItemIcon {
  position: absolute;
  top: 9px;
  left: 15px;
}
