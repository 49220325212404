.container {
  margin-top: 5%;
  margin-bottom: 20px;
}

.title {
  color: #007DBA;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.suggestion {
  color: #757E95;
}