.container {
  width: 100%;
  background-color: white;
  border-radius: 14px;
}

.content {
  margin: 4px 16px 4px;
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

.icon {
  width: 64px;
  height: 48px;
  margin: 0px 16px 0px 0px;
}

/*
 * Tablet resolution
 */
@media (min-width: 688px) {

  .container {
    height: 160px;
  }

  .content {
    margin: 24px 56px 32px;
    padding: 32px 0px;
  }

  .icon {
    width: 120px;
    height: 88px;
    margin: 0px 32px 0px 0px;
  }

  .content {
    margin: 24px 16px 32px;
    padding: 24px 0px;
  }
}

