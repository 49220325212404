.coverMembersText {
  margin-left: 12px !important;
}

.coveredTeamMembers {
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  width: 90%;
  max-width: 976px;
  padding: 20px 30px 20px 30px;
}

.serviceBoxContainer{
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}

.serviceBox {
  flex-basis: 50%;
  max-width: 258px;
  margin-top: 20px;
  padding: 36px 0px 28px 0px;
}

.serviceName {
  font-weight: 600;
}

.keepServiceButton {
  margin-left: 0px;
}

.cancellationOption {
  background-color: white;
  border-radius: 14px;
}

.textAreaMessage {
  width: 100%;
  height: 216px !important;
  border-radius: 14px;
  outline: none;
  padding: 12px;
  font-family: 'Open Sans';
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {

  .cancelButton {
    width: 270px;
    border: 1px solid #DBDBDB !important;
    order: 0;
    margin-top: 0px;
  }

  .coveredTeamMembers {
    padding: 38px 48px 20px 38px;
  }

  .serviceBoxContainer{
    justify-content: space-between;
  }

  .serviceBox {
    flex-basis: 25%;
  }

}

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {

  .featuresContainer {
    flex-basis: 50%;
    padding-left: 0px;
  }

  .coveredTeamMembers {
    padding: 38px 26px 20px 26px;
  }

}
