.statusItems {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.statusItemContainer {
    flex-basis: 33.333333%;
    text-align: center;
}

.attentionNumber {
    font-weight: bold;
    color: #0A9A1A;
}

.bbbTitle {
    color: #007DBA;
}

.needsAttention {
    color: #F75F34;
}

@media (max-width: 1023px) {
    .statusItemContainer {
        flex-basis: 50%;
    }
}

@media (max-width: 767px) {
    .systemStatusPanel {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 15px;
    }

    .statusItems {
        margin-top: 0;
    }

    .statusItemContainer {
        flex-basis: 100%;
    }
}