.container {
  border-radius: 14px;
  height: 680px;
  background-color: white;
  outline: 0;
}

.content {
  margin: 24px 16px 56px 16px;
}

.titleIcon {
  width: 144px;
  height: 144px;
}

.metricIcon {
  width: 96px;
  height: 96px;
}

.closeIconContainer {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  min-width: 50px;
  min-height: 50px;
}

.customButton {
  width: 184px;
  outline: none;
  height: 40px;
}

.settingsText {
  max-width: 104px;
  width: 92px;
}

.switch {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {
  .content {
    margin: 24px 96px 56px 96px;
  }

  .metricIcon {
    margin-left: 0px;
  }

  .closeIcon {
    left: 64px;
  }

  .switch {
    justify-content: center;
    display: flex;
    flex-direction: row;
  }
}
