.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "open sans", "arial", "sans serif";
}

header {
  text-align: center;
}

.clickHere {
  background: none;
  border: none;
  cursor: pointer;
  font-family: "open sans", "arial", "sans serif";
  font-size: 20px;
}

a,
.clickHere {
  color: #f1582d;
  text-decoration: underline;
}

.title {
  font-size: 50px;
  color: #666666;
  font-weight: 400;
  margin: 0;
  margin-top: 16px;
}

.subtitle {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
  color: #666666;
}

.subtitle > span {
  font-weight: 700;
}

.subtitleClickhere {
  font-size: 20px;
  font-weight: 400;
  color: #666666;
}

.stepsContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 902px;
}

.stepsBackground {
  position: absolute;
  z-index: 1;
  width: 902px;
}

.steps {
  display: flex;
  justify-content: space-around;
  width: 902px;
}

.step {
  width: 268px;
  height: 348px;
  border-radius: 32px;
  background-color: #ffffff;
  z-index: 10;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepHeader {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}

.stepOrder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #53b700;
  color: #ffffff;
  font-weight: 700;
  margin-right: 10px;
  margin-left: 20px;
}

.stepTitle {
  color: #5d5d5d;
  font-size: 20px;
}

.stepImage {
  width: 206px;
}

.stepDescription {
  width: 224px;
  text-align: center;
  font-size: 16px;
  color: #5d5d5d;
  position: absolute;
  bottom: 25px;
  height: 70px;
}

.footer {
  width: 902px;
  border-top: 1px solid #757e95;
  margin-top: 60px;
  padding-top: 20px;
}

.footerLogoLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding-right: 60px;
}

.copyright {
  font-size: 12px;
  color: #757e95;
  margin-top: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.vIndicator,
.hIndicator {
  position: fixed;
  z-index: 1001;
}

@keyframes verticalBounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.verticalBounce {
  animation-name: verticalBounce;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes horizontalBounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(275px);
  }
  100% {
    transform: translateX(0);
  }
}

.horizontalBounce {
  animation-name: horizontalBounce;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
