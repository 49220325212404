.container {
  height: 42px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  padding-left: 50px;
  display: flex;
  align-items: center;
  color: #757e95;
}

.svgContainer {
  margin-left: 23px;
  margin-right: 23px;
}

.gt {
  fill: none;
  stroke: #d6d6d6;
  stroke-linecap: round;
  stroke-width: 2px;
}

.path {
  color: #757e95;
  font-weight: 400;
  text-decoration: none;
}

.lastPath {
  color: #535a6a;
  font-weight: 600;
}
