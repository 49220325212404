.progressContainer {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.titleIcon {
  margin-right: 8px;
}

.addIcon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.calendarIcon {
  margin-left: 4px;
}

.defaultPayment {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  opacity: 1;
}

.customButton {
  width: 240px;
}

.expireDateBox {
  display: none;
}

.cardTypeText {
  margin-left: 8px;
}

.lastDigitsText {
  margin-left: 8px;
}

.searchMobile{
  display: block;
}

.nameText {
  margin-left: 32px;
}

.accordionTotalAmount {
  margin-left: 100px;
}

.roleText {
  display: none;
}

.settingsBox {
  margin-right: 22px;
  flex-basis: 33.3%;
  text-align: end;
  justify-content: center;
}

.coloredRow {
  background-color: rgb(229, 234, 238, 0.36);
  border-radius: 8px;
}

.invoiceRow {
  display: block;
  width: 100%;
}

.nameTextContainer {
  margin-left: 14px;
  width: 160px;
  flex-basis: 66.6%;
}

.roleUnderName {
  display: block;
  text-align: start;
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {

  .accordion {
    display: none;
  }

  .roleUnderName {
    display: none;
  }

  .searchMobile{
    display: none;
  }

  .searchTablet {
    display: flex;
  }

  .tableHeader {
    display: block;
    height: 48px;
    background-color: rgb(229, 234, 238, 0.36);
    border-radius: 8px;
    width: 100%;
    display: flex;
    padding: 12px 32px 12px 32px;
  }

  .headerDate {
    font-weight: 600;
  }

  .headerTotal {
    font-weight: 600;
    margin-left: 160px;
  }

  .settingsBox {
    margin-right: 0px;
    display: flex;
    flex-basis: 33.3%;
    text-align: end;
    justify-content: flex-end;
  }

  .headerPlan {
    font-weight: 600;
    margin-left: 160px;
  }

  .headerDownload {
    font-weight: 600;
    margin-left: 112px;
  }

  .nameTextContainer {
    margin-left: 14px;
    width: 340px;
    flex-basis: 45.5%;
  }

  .nameText {
    margin-left: 40px;
  }

  .roleText {
    display: flex;
    text-align: left;
    width: 64px;
    margin-left: 20px;
    flex-basis: 33.3%;
  }

  .planText {
    text-align: left;
    width: 100px;
    margin-left: 120px;
  }

  .downloadLabel {
    font-style: italic;
    margin-top: -10px;
    margin-left: -20px;
  }

  .settingsContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-right: 58px;
  }
}

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {
  .tableHeader {
    display: block;
    height: 48px;
    background-color: rgb(229, 234, 238, 0.36);
    border-radius: 8px;
    width: 100%;
    display: flex;
    padding: 12px 80px 12px 36px;
  }

  .coloredRow {
    background-color: rgb(229, 234, 238, 0.36);
    border-radius: 8px;
  }

  .headerDate {
    font-weight: 600;
  }

  .headerTotal {
    font-weight: 600;
    margin-left: 200px;
  }

  .headerPlan {
    font-weight: 600;
    margin-left: 178px;
  }

  .headerDownload {
    font-weight: 600;
    margin-left: 240px;
  }

  .nameTextContainer {
    margin-left: 14px;
    width: 220px;
    flex-basis: auto;
  }

  .nameText {
    text-align: left;
    margin-left: 40px;
  }

  .roleText {
    width: 64px;
    margin-left: 48px;
    flex-basis: 33.3%;
  }

  .planText {
    text-align: left;
    margin-left: 148px;
    width: 100px;
  }

  .downloadLabel {
    font-style: italic;
    margin-top: -10px;
    margin-left: -20px;
  }

  .settingsContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-width: 50px;
  }

  .settingsBox {
    display: flex;
    flex-basis: 33.3%;
    text-align: end;
    justify-content: flex-end;
  }
}
