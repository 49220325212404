.teamScreen {
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.teamHeader {
  width: 100%;
}

.teamHeaderBackgroundContainer {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 383px;
  overflow: hidden;
}

.teamHeaderBackground {
  width: 100%;
  min-height: 100%;
}

.teamHeaderBackground1200 {
  display: block;
}

.teamHeaderBackground1024,
.teamHeaderBackground768,
.teamHeaderBackground360 {
  display: none;
}

.teamHeaderContent {
  margin: 25px 30px;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.teamHeaderContent > div {
  vertical-align: middle;
}

.teamHeaderTextContainer {
  width: 100%;
  text-align: center;
  flex-grow: 1;
  padding: 20px;
  flex-wrap: wrap;
}

.teamHeaderEmphasis {
  font-weight: 600;
  cursor: pointer;
}

.teamHeaderImageContainer {
  flex-grow: 0;
  padding: 5px;
}

.teamHeaderButtonContainer {
  padding: 0 20px;
  flex-grow: 0;
}

.teamHeaderActionButton {
  width: 255px;
  height: 44px;
  vertical-align: middle;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}

.teamBody {
  margin: 0 52px;
  padding: 30px;
}

.mobile {
  display: none;
}

.teamAddMembersBox {
  text-align: center;
  margin-top: 4px;
  font-size: 21px;
  font-weight: 600;
}

/*
   * Tablet resolution
   */
@media (min-width: 768px) {
  .teamAddMembersBox {
    text-align: left;
    margin-top: 0px;
  }

  .teamHeaderTextContainer {
    text-align: left;
  }

  .teamHeaderActionButton {
    margin-bottom: 0px;
  }
}

@media (max-width: 1199px) {
  .teamHeaderBackground1024 {
    display: inline;
  }

  .teamHeaderBackground1200,
  .teamHeaderBackground768,
  .teamHeaderBackground360 {
    display: none;
  }
}

@media (max-width: 1023px) {
  .teamHeaderContent {
    text-align: center;
  }

  .teamHeaderBackgroundContainer {
    height: 357px;
  }

  .teamHeaderBackground768 {
    display: inline;
  }

  .teamHeaderBackground1200,
  .teamHeaderBackground1024,
  .teamHeaderBackground360 {
    display: none;
  }

  .teamBody {
    margin: 0 26px;
  }

  .teamHeaderButtonContainer {
    display: none;
  }

  .teamHeaderButtonContainer.mobile {
    display: inline-block;
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .teamHeaderBackground360 {
    display: inline;
  }

  .teamHeaderBackgroundContainer {
    height: 350px;
  }

  .teamHeaderBackground1200,
  .teamHeaderBackground1024,
  .teamHeaderBackground768 {
    display: none;
  }
  .teamBody {
    margin: 0;
  }

  .teamHeaderContent {
    margin: 0;
  }

  .teamHeaderImageContainer {
    display: none;
  }

  .teamHeaderTitle {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 22px;
  }

  .teamBody {
    padding: 0;
  }
}
