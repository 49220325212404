.tabContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tabDescription {
    font-size: 16px;
    line-height: 24px;
    color: #757e95;
    max-width: 281px;
}

.deceptorsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;
    max-width: 860px;
}

.deceptorEntryContainer {
    flex-basis: 33.33333%;
    display: inline-flex;
    justify-content: center;
}

.deceptorEntry {
    display: inline-flex;
    width: 258px;
    height: 193px;
    box-sizing: border-box;
    padding: 30px 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    margin-bottom: 20px;
}

.deceptorEntry.showLastBlocked {
    height: 238px;
}

.deceptorEntryImage {
    width: 43px;
    height: 43px;
}

.deceptorEntryTitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #535a6a;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
}

.lastBlocked {
    color: #f1582d;
    font-size: 16px;
    line-height: 22px;
    opacity: 0.84;
}

.viewDetailsButton {
    margin-top: 19px;
}

.emptyContent {
    padding: 150px 0;
}

.emptyImage {
    width: 85px;
    height: 80px;
}

.emptyTitle {
    font-size: 21px;
    line-height: 26px;
    color: #0084A3;
}

.emptyDescription {
    font-size: 16px;
    line-height: 24px;
    color: #757E95;
}

@media (max-width: 1023px) {
    .deceptorEntryContainer {
        flex-basis: 50%;
    }

    .emptyContent {
        padding: 100px 0;
    }
}

@media (max-width: 1024px) and (max-height: 768px) {
    .emptyContent {
        padding: 70px 0;
    }
}

@media (max-width: 767px) {
    .deceptorEntryContainer {
        flex-basis: 100%;
    }

    .emptyContent {
        padding: 45px 0;
    }
}
