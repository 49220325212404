.container {
  border-radius: 14px;
  height: 680px;
  background-color: white;
  outline: 0;
}

.content {
  margin: 24px 16px 56px 16px;
}

.titleIcon {
  width: 144px;
  height: 144px;
}

.metricIcon {
  width: 96px;
  height: 96px;
  margin-left: 24px;
}

.closeIconContainer {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  min-width: 50px;
  min-height: 50px;
}

.customButton {
  width: 184px;
  outline: none;
  height: 40px;
}

.textMetric {
  word-wrap: break-word;
}

.dividerBottomModal {
  display: none;
}

  /*
   * Tablet resolution
   */
   @media (min-width: 688px) {
    .content {
      margin: 24px 80px 56px 80px;
    }

    .metricIcon {
      margin-left: 0px;
    }
    
    .closeIcon {
      left: 64px;
    }

    .dividerBottomModal {
      display: block;
    }
  }