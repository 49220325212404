.container {
  width: 100%;
  height: 240px;
  border-radius: 14px;
}

.content {
  margin: 24px 56px 32px;
  display: flex;
  align-items: center;
}

.icon {
  width: 88px;
  height: 88px;
  margin: 0px 32px 0px 0px;
}
