.navBar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
  height: 100%;
  padding: 0 10px;
}

.navBarActions {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}

.mobileWrapper {
  width: 361px;
}

.notificationImage {
  width: 23px;
  height: 24px;
  align-self: center;
}

.userContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 13px;
  cursor: pointer;

}

.userContainer .userName {
  font-weight: 600;
  margin: 0 10px;
}

.dropdownContainer.hidden {
  display: none;
}

.dropdownContainer {
  position: absolute;
  top: 67px;
  right: 9px;
  width: 209px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: -4px 5px 8px #43566420;
  align-items: center;
  border-radius: 6px;
  padding: 18px 30px;
  z-index: 10;
}

.dropdownItem {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  color: #535A6A;
}

.dropdownItem:not(:last-child) {
  margin-bottom: 18px;
}

.dropdownItemIcon {
  width: 18px;
  height: 18px;
  margin-right: 30px;
}

.dropdownDivider {
  width: 155px;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 15px;
}