.teamMemberTableRow td {
  padding: 30px 0;
}

.teamMemberTableRowCellContent {
  display: flex;
  height: 100%;
  align-items: center;
}

.statusCell {
  display: flex;
  height: 100%;
}

.resendCell {
  cursor: pointer;
  margin-top: 4px;
  margin-left: 20px;
}

.teamMemberTableRowInfoImageContainer {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 39px;
}

.teamMemberTableRowPendingIndicator {
  position: absolute;
  top: -4px;
  left: 28px;
  width: 12px;
  height: 12px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  background: #535a6a 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.teamMemberTableRowPendingIndicator {
    position: absolute;
    top: -4px;
    left: 28px;
    width: 12px;
    height: 12px;
    transform: matrix(0, -1, 1, 0, 0, 0);
    background: #535A6A 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
}

.teamMemberTableRowOnlineIndicator {
  position: absolute;
  top: -4px;
  left: 28px;
  width: 12px;
  height: 12px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  background: #2ecc5f 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.teamMemberTableRowOfflineIndicator {
  position: absolute;
  top: -4px;
  left: 28px;
  width: 12px;
  height: 12px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  background: #cc4040 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.teamMemberTableRowMainText {
  font-size: 16px;
  line-height: 22px;
  color: #535a6a;
}

.teamMemberTableRowSubText {
  display: block;
  font-style: italic;
  color: #757e95;
}

.removeMessage {
  margin-left: -8px !important;
}

.teamMemberTableRowDevice .teamMemberTableRowSubText {
  font-style: normal;
}

.teamMemberTableRow .teamMemberTableRowDetailsButton {
  width: 129px;
  height: 35px;
  padding: 0;
}

.disabledButton {
  background: #d6d6d6 0% 0% no-repeat padding-box !important;
}

.disabledButton {
    background: #D6D6D6 0% 0% no-repeat padding-box !important;
}

.teamMemberTableRowDeviceImage {
  margin-right: 22px;
}

.pendingIcon {
  padding-left: 8px;
}

.pendingIcon {
    padding-left: 8px;
}

.teamMemberTableRowstatusImage {
  margin-right: 12px;
}

.teamMemberTableRowDeleteButton {
  background: transparent;
  border: none;
  cursor: pointer;
}

.teamMemberTableRowAdminButton {
  background: transparent;
  border: none;
}

.teamMemberTableRowAction {
  text-align: right;
}

.progressContainer {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.teamMemberTableRowDeviceText {
  margin-top: 4px;
}

.teamMemberTableRowStatusContainer {
  margin-left: 34px;
}

/*
   * Tablet resolution
   */
@media (min-width: 768px) {
  .teamMemberTableRowDelete {
    padding-left: 8px !important;
  }

  .resendCell {
    margin-left: 32px;
  }
}

@media (max-width: 1023px) {
  .teamMemberTableRowInfoImageContainer {
    margin-right: 12px;
  }

  .teamMemberTableRowDeviceImage {
    margin-right: 7px;
    margin-left: 28px;
    max-width: 32px;
  }
}

@media (max-width: 767px) {
  .teamMemberTableRow {
    display: block;
    position: relative;
  }

  .teamMemberTableRow td {
    display: block;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 16px;
    padding-left: 20px;
    height: 72px;
  }

  .teamMemberTableRowInfo {
    background-color: rgba(204, 204, 204, 0.1);
  }

  .teamMemberTableRowInfo .teamMemberTableRowCellContent {
    margin-right: 80px;
  }

  .teamMemberTableRowInfoImageContainer {
    width: 50px;
    margin-right: 30px;
  }

  .teamMemberTableRowDevice > *:first-child::before {
    content: "Computer";
    color: #535a6a;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    width: 76px;
  }

  .teamMemberTableRowStatus {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  .teamMemberTableRowStatus > *:first-child::before {
    content: "Status";
    color: #535a6a;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    width: 76px;
  }

  .teamMemberTableRowDelete {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    border-radius: 12px 0px 0px 12px;
    background-color: rgba(198, 198, 198, 0.29);
  }

  .teamMemberTableRowDeleteButton {
    width: 100%;
  }

  .teamMemberTableRowAction {
    text-align: center;
  }

  .teamMemberTableRow .teamMemberTableRowDetailsButton {
    width: 80%;
  }
}
