.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1040px;
  margin: 30px auto;
}

.header {
  margin-bottom: 52px;
  text-align: center;
}

.progressBar {
  width: 90%;
}

.stats {
  display: flex;
  justify-content: center;
  margin: 50px auto;
  text-align: center;
}

.statsElement {
  margin: 0px 5px;
}

@media (max-width: 360px) {
  .header {
    margin-bottom: 40px;
  }

  .statsValue {
    display: block;
  }
}
