.fieldRow {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.removeIcon {
  max-width: 24px;
  max-height: 24px;
}

.textRemove {
  margin-left: 6px !important;
}

.removeContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 24px;
  margin: 8px auto 8px;
}

.fieldBarContainer {
  flex: 1;
  display: block;
  width: 100%;
  margin-bottom: 8px;
}

.fieldBar {
  border-color: "#F4F4F4";
  width: 100%;
  max-height: 1;
}

.field {
  flex: 1;
  max-height: 72px;
  width: 100%;
  max-width: 320px;
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {
  .fieldRow {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .field {
    flex: 50%;
    width: 100%;
    max-width: 264px;
    margin-bottom: 32px;
  }

  .removeContainer {
    flex: 10%;
    flex-direction: column;
    max-height: 48px;
    align-items: center;
    margin: 24px auto 24px;
    max-width: 48px;
    align-items: center;
  }

  .fieldBar,
  .fieldBarContainer {
    display: none;
  }
}

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 688px) and (max-height: 768px), (min-width: 1024px) {
  .field {
    max-width: 300px;
  }
}
