.container {
  max-width: 1075px;
  width: 100%;
  margin: 0px;
}

@media (min-width: 768px) {
  .container {
    max-width: 1075px;
    width: 100%;
  }
}

.content {
  padding: 35px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #535a6a;
}

.ticket {
  display: grid;
  grid-template-columns: 0.5fr 1.3fr 1fr 1.2fr 1fr 1fr;
  border-radius: 5px;
  padding: 20px;
  align-items: center;
  text-align: center;
  column-gap: 10px;
  row-gap: 5px;
}

.ticket > div {
  text-align: start;
}

.ticket:nth-child(1) {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  border-style: none;
  border-top: solid 1px;
  border-bottom: solid 1px;
  border-color: #e5e5e5;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 43px;
  margin-bottom: 43px;
  font-weight: 600;
  color: #535a6a;
}

.ticket:nth-child(even) {
  background-color: rgba(229, 234, 238, 0.36);
}

.ticketNumber {
  color: #535a6a;
  font-size: 16px;
  font-weight: 600;
}

.topic {
  color: #535a6a;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
}

.requestStatus {
  color: #535a6a;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.requestStatus > img {
  margin-right: 10px;
}

.lastUpdated {
  color: #757e95;
  font-size: 14px;
  font-style: italic;
}

.createdBy {
  color: #535a6a;
  font-size: 16px;
  font-weight: 400;
}

.detailsButton {
  display: flex;
  justify-content: center;
}

.createTicketContainer {
  max-width: 596px;
  padding: 0 29px 39px;
}

.createTicketTitle {
  color: #535a6a;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.createTicketSubitle {
  color: #757e95;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 30px;
}

.helpForm {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

.formActions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  width: 200px;
  align-self: flex-end;
}

.detailsContainer {
  max-width: 1075px;
  width: 100%;
}

.ticketDetailsHeader {
  display: flex;
  align-items: center;
  color: #007dba;
  margin-bottom: 20px;
}

.ticketDetailsTitle {
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
}

.ticketDetailsRow:not(:last-of-type) {
  border-style: none;
  border-bottom: solid 1px;
  border-color: #e5e5e5;
}

.ticketDetailsRow {
  padding-bottom: 15px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.emailContainer {
  border-bottom: 3px solid #e5e5e5;
}

.emailTable {
  border-spacing: 0;
}

.emailTable td,
.emailTable th {
  padding: 10px;
}

.emailTable tr {
  border-radius:  8px;
}

.emailTable thead tr {
  background-color: #E5EAEE;
}

.emailTable tbody tr:nth-child(even) {
  background-color: #E5EAEE;
}

@media (max-width: 767px) {
  .ticketDetailsRow:not(.messagesRow) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .messagesRow {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .messagesRow .label {
    margin-right: 10px;
  }
}

@media (min-width: 744px) {
  .ticketDetailsRow {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
}

.label {
  color: #007dba;
  font-size: 16px;
  font-weight: 600;
}

.value {
  color: #757e95;
  font-size: 18px;
  font-weight: 400;
}

.createdByValue {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.username {
  margin-left: 5px;
  color: #535a6a;
  font-size: 18px;
  font-weight: 600;
}

.mobileContent {
  margin: 0;
  padding: 0;
}

.mobileHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-bottom: solid 1px;
  border-color: #e5e5e5;
  padding-top: 5px;
  padding-bottom: 15px;
  margin-bottom: -10px;
}

.mobileTitle {
  color: #535a6a;
  font-size: 18px;
  font-weight: 600;
}

.mobileTicket {
  height: 100%;
}

.mobileTicketTitle {
  height: 55px;
  color: #535a6a;
  font-size: 14px;
  font-weight: 700;
  background-color: #f1f1f1;
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 10px;
  position: sticky;
  top: 0;
}


.mobileTicketContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 60px;
  row-gap: 10px;
  height: 100%;
}

.mobileLabel {
  margin-left: 20px;
  color: #535a6a;
  font-size: 16px;
  font-weight: 600;
}

.mobileValue {
  color: #535353;
  font-size: 16px;
  font-weight: 400;
}

.mobileValueDate {
  color: #757e95;
  font-size: 14px;
  font-style: italic;
}

.mobileLabelCreatedBy,
.mobileValueCreatedBy {
  border-style: none;
  border-bottom: solid 1px;
  border-top: solid 1px;
  border-color: #e5e5e5;
  display: flex;
  align-items: center;
}

.mobileDetailsButton {
  grid-column: 1/3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}