.lockedFilesDialog {
    border-radius: 20px;
    padding: 14px 24px 24px;
    width: 619px;
}

.lockedFilesDialogContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
}

.headerImage {
    width: 112px;
    height: 97px;
    margin-bottom: 13px;
}

.analysisContainer {
    border-radius: 12px;
    width: 100%;
    margin-bottom: 46px;
}

.lockedFiles {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-height: 300px;
    overflow: auto;
}

.lockedFilesEntry {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}

.lockedFilesEntry:nth-child(odd) {
    background-color: #F8F8F8;
    border-radius: 12px;
}

.fileName {
    cursor: pointer;
    flex-basis: 80%;
    overflow-wrap: anywhere;
}

.footer {
    padding-top: 38px;
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.backButton {
    width: 277px;
    height: 44px;
    font-size: 16px;
    line-height: 17px;
}

@media (max-width: 767px) {
    .lockedFilesDialog {
        padding: 14px 8px 24px;
        width: unset;
    }
        
    .lockedFilesEntry {
        padding: 15px 13px;
        flex-direction: column;
        align-items: unset;
    }

    .fileName {
        flex-basis: unset;
    }

    .backButton {
        display: none;
    }

    .footer {
        border-top: 1px solid #D3D3D3;
        padding-top: 30px;
    }
}