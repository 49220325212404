.analysisCompleteDialog {
    border-radius: 20px;
    padding: 14px 24px 24px;
}

.analysisCompleteDialogContent {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
}

.headerImage {
    width: 50px;
    height: 50px;
    margin-bottom: 13px;
}

.analysis {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 38px;
}

.analysisCategory {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}

.analysisCategory.noClick {
    cursor: unset;
}

.analysisCategory:nth-child(odd) {
    background-color: #F8F8F8;
    border-radius: 12px;
}
.categoryName {
    cursor: pointer;
    text-align: left;
    flex-basis: 33%;
}

.cleanButton {
    width: 195px;
    height: 44px;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .analysisCompleteDialog {
        padding: 14px 8px 24px;
    }

    .analysisCategory {
        padding: 15px 13px;
    }
}