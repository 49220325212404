.teamMemberTable {
    width: 100%;
    border-collapse: collapse;
}

.teamMemberTable tr {
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.teamMemberTable th {
    height: 72px;
    font-weight: normal;
}

.teamMemberTable th:last-of-type {
    text-align: right;
}

.teamMemberTableControls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 20px;
}

.teamMemberTableControls > div {
    flex-grow: 0;
    vertical-align: middle;
    text-align: center;
}

.teamMemberTableControls > div:not(:first-of-type) {
    margin-left: 24px;
}

.teamMemberTableControls .teamMemberTableControlsSpacer {
    flex-grow: 1;
}

.teamMemberTableControls img {
    margin-right: 12px;
    vertical-align: middle;
}

.teamMemberTableControls span {
    vertical-align: middle;
}

.computerColumn {
    padding-left: 20px;
}

.statusColumn {
    padding-left: 24px;
}

.actionsColumns {
    padding-right: 24px;
}

@media (max-width: 767px) {
    .teamMemberTable {
        display: block;
    }

    .teamMemberTable thead {
        display: none;
    }

    .teamMemberTable tbody {
        display: block;
    }

    .teamMemberTableControls {
        flex-wrap: wrap;
        margin-bottom: 0;
        background-color: #FFFFFF;
    }

    .teamMemberTableControls img {
        margin-right: 8px;
    }

    .teamMemberTableControls > div {
        border: 1px solid #E8E8E8;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
    }
    .teamMemberTableControls > div:not(:first-of-type) {
        margin-left: 0;
    }

    .teamMemberTableControls .teamMemberTableControlsTeam {
        width: 100%;
    }

    .teamMemberTableControls .teamMemberTableControlsSpacer {
        display: none;
    }
}