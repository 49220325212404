.container {
    border-radius: 20px;
    width: 320px;
    background-color: white;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: 0;
  }
  
  .content {
    margin: 16px 24px 56px 24px;
    align-items: center;
  }
  
  .icon {
    display: block;
    margin: auto;
    animation: jump 1.2s;
  }
  
  @keyframes jump {
    0% {
      transform: scale(1.25, 0.75);
    }
    50% {
      transform: scale(1, 1);
    }
    55% {
      transform: rotate(15deg);
    }
    60% {
      transform: rotate(-15deg);
    }
    65% {
      transform: rotate(15deg);
    }
    70% {
      transform: rotate(-15deg);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  .cancelButtonText {
    color: #535a6a;
  }
  
  .customButton {
    width: 200px;
    outline: none;
  }

  .title {
      font-size: 21px;
      font-weight: 600;
      color: #535A6A;
      text-align: center;
  }
  
  /*
     * To avoid collapse the button with the div for small sizes 
     */
  @media (max-width: 310px) {
    .customButton {
      width: 90%;
    }
  }
  
    /*
     * Tablet resolution
     */
  @media (min-width: 768px) {
    .container {
      width: 516px;
    }
  
     .content {
      margin-left: 40px;
      margin-right: 40px;
    }
  
  }
  