.deviceOverviewItems {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
}

.deviceOverviewDivider {
    width: 1px;
    min-height: 100%;
    border-right: 1px solid #DBDBDB;
    margin: 0 20px;
}

.deviceOverviewInfo {
    text-align: left;
    color: #535A6A;
}

.deviceOverviewInfoField {
    margin-bottom: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.deviceOverviewInfoField > * {
    vertical-align: top;
}

.deviceOverviewInfoFieldIcon {
    margin-right: 12px;
}

.deviceOverviewInfoFieldText {
    display: inline-block;
}

.deviceOverviewAction {
    vertical-align: top;
    text-align: center;
}

.deviceOverviewActionButton {
    width: 196px;
    height: 44px;
}

.computerImage {
    max-width: 160px;
    max-height: 160px;
}

@media (max-width: 1024px) {
    .deviceOverviewDivider.leftDivider {
        display: none;
    }
    
}

@media (max-width: 768px) {
    .deviceOverview {
        box-sizing: border-box;
        width: 100%;
        margin: 0;
    }

    .deviceOverviewDivider {
        display: none;
    }

    .deviceOverviewItems {
        flex-wrap: wrap;
    }

    .deviceOverviewImage {
        margin-bottom: 20px;
    }

    .deviceOverviewInfoField {
        margin-bottom: 12px;
    }

    .deviceOverviewActionDescription {
        margin-bottom: 15px;
    }
}