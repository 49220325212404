.progressContainer {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.titleIcon {
  width: 26.75px;
  height: 18.62px;
  margin-right: 8px;
}

.addIcon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.calendarIcon {
  margin-left: 4px;
}

.defaultPayment {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  opacity: 1;
}

.customButton {
  width: 280px;
}

.expireDateBox {
  display: none;
}

.cardTypeText {
  margin-left: 8px;
}

.lastDigitsText {
  margin-left: 8px;
}

.tableHeader {
  display: none;
}

.searchMobile{
  display: block;
}

.searchTablet {
  display: none;
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {

  .searchMobile{
    display: none;
  }

  .searchTablet {
    display: flex;
  }

  .tableHeader {
    display: block;
    height: 48px;
    background-color: rgb(229, 234, 238, 0.36);
    border-radius: 8px;
    width: 100%;
    display: flex;
    padding: 12px 32px 12px 32px;
  }

  .coloredRow {
    background-color: rgb(229, 234, 238, 0.36);
    border-radius: 8px;
  }

  .headerDate {
    font-weight: 600;
  }

  .headerTotal {
    font-weight: 600;
    margin-left: 160px;
  }

  .headerPlan {
    font-weight: 600;
    margin-left: 160px;
  }

  .headerDownload {
    font-weight: 600;
    margin-left: 112px;
  }

  .dateTextContainer {
    margin-left: 14px;
    width: 180px;
  }

  .dateText {
    margin-left: 14px;
  }

  .totalText {
    margin-left: 28px;
  }

  .planText {
    margin-left: 134px;
  }

  .downloadLabel {
    font-style: italic;
    margin-top: -10px;
    margin-left: -20px;
  }

  .downloadContainer {
    display: flex;
    flex-direction: column;
    margin-left: 88px;
    cursor: pointer;
  }
}

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {
  .tableHeader {
    display: block;
    height: 48px;
    background-color: rgb(229, 234, 238, 0.36);
    border-radius: 8px;
    width: 100%;
    display: flex;
    padding: 12px 80px 12px 36px;
  }

  .coloredRow {
    background-color: rgb(229, 234, 238, 0.36);
    border-radius: 8px;
  }

  .headerDate {
    font-weight: 600;
  }

  .headerTotal {
    font-weight: 600;
    margin-left: 200px;
  }

  .headerPlan {
    font-weight: 600;
    margin-left: 178px;
  }

  .headerDownload {
    font-weight: 600;
    margin-left: 240px;
  }

  .dateTextContainer {
    margin-left: 14px;
    width: 180px;
  }

  .dateText {
    margin-left: 14px;
  }

  .totalText {
    margin-left: 56px;
  }

  .planText {
    margin-left: 164px;
  }

  .downloadLabel {
    font-style: italic;
    margin-top: -10px;
    margin-left: -20px;
  }

  .downloadContainer {
    display: flex;
    flex-direction: column;
    margin-left: 208px;
    cursor: pointer;
  }
}
