.ticketSubmittedDialogContent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: -30px auto 30px;
  min-height: 260px;
}

.textContainer {
  text-align: center;
}

.ticketSubmittedImage {
  width: 75px;
  height: 75px;
}

.title {
  font-size: 21px;
  line-height: 26px;
}

.textContainer .description {
  max-width: 294px;
  margin: 0 auto;
}

.closeButton {
  width: 245px;
}