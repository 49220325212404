.container {
  background-color: #ffffff;
  box-shadow: 0px 10px 20px #00000033;
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 58px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 600px;
  max-width: 90%;
  overflow: hidden;
  padding-top: 54px;
  padding-bottom: 45px;
}

.header {
  text-align: center;
}

.title {
  color: #535a6a;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
}

.subtitle {
  color: #757e95;
  font-size: 16px;
  font-weight: 400;
  width: 90%;
  max-width: 408px;
  display: block;
  margin: auto;
  margin-top: 8px;
}

.summary {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 348px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.summaryHeader {
  margin-bottom: 10px;
}

.summaryTitle {
  color: #535A6A;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  margin-bottom: 8px;
}

.summarySubtitle {
  font-weight: 600;
  color: #535a6a;
  font-size: 16px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: solid 1px #d3d3d3;
}

.rowTotal {
  padding-bottom: 18px;
}

.label {
  color: #535a6a;
}

.value {
  color: #535a6a;
}

.total {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.totalValue {
  color: #007dba;
  font-size: 16px;
  font-weight: 600;
}

.frequency {
  color: #535a6a;
  font-size: 12px;
  font-weight: 600;
}

.description {
  color: #535a6a;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: baseline;
  width: 90%;
  max-width: 390px;
  margin-bottom: 20px;
}

.description > p {
  margin: 0;
}

.detailsIcon {
  margin-right: 8px;
}

.renew {
  color: #535a6a;
  font-size: 14px;
  font-weight: 600;
}

.customButton {
  max-width: 256px;
  width: 90%;
  outline: none;
  height: 40px;
}
