.container {
  border-radius: 20px;
  width: 320px;
  background-color: white;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
}

.content {
  margin: 16px 24px 56px 24px;
  align-items: center;
}

.closeIconContainer {
  margin-bottom: 32px;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  min-width: 50px;
  min-height: 50px;
}

.icon {
  margin: auto;
  width: 100%;
  max-height: 50px;
  animation: jump 1.2s;
}

@keyframes jump {
  0% {
    transform: scale(1.25, 0.75);
  }
  50% {
    transform: scale(1, 1);
  }
  55% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-15deg);
  }
  65% {
    transform: rotate(15deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  100% {
    transform: scale(1, 1);
  }
}

.cancelButtonText {
  color: #535a6a;
}

.customButton {
  width: 256px;
  outline: none;
}

.optionRole {
  border: 1px solid #D1D1D1;
  border-radius: 8px;
  height: 50px;
  padding: 14px 0px 14px 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 460px;
}

.optionRoleSelected {
  background: #F4FDFF 0% 0% no-repeat padding-box;
  border: 1px solid #17ADF0;
  border-radius: 8px;
  font-weight: 600;
}

/*
   * To avoid collapse the button with the div for small sizes 
   */
@media (max-width: 310px) {
  .customButton {
    width: 90%;
  }
}

  /*
   * Tablet resolution
   */
@media (min-width: 688px) {
  .container {
    width: 622px;
  }

   .content {
    margin-left: 40px;
    margin-right: 40px;
  }

}
