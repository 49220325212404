.appRoot {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.navSlot {
  min-height: 58px;
  margin-bottom: 25px;
}

.contentSlot {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  overflow:auto;
}

.remoteSlot{
  height: 50px;
  background-color: red;
}

@media (max-width: 767px) {
  .navSlot {
    margin-bottom: 5px;
  }
}