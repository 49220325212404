.field {
  width: 100%;
}

.cvvIcon {
  width: 74.82px;
  height: 48.92px;
}

.importantText {
  color: "#BE2222",
}

.dateContainer {
  flex-basis: 100%;
  margin-top: 8px;
}

.responsiveBox {
  flex-basis: 100%;
  margin-top: 16px;
}

  /*
   * Tablet resolution
   */
   @media (min-width: 688px) {
    .dateContainer {
      flex-basis: 50%;
      margin-top: 4px;
    }

  }