.container {
  width: 280px;
  height: 272px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  margin: auto;
}

.content {
  margin: 32px auto 40px;
  display: flex;
  flex-wrap: wrap;
}

.icon {
  width: 64px;
  height: 64px;
}

.customButton {
  width: 200px;
  outline: none;
  height: 40px;
}
