.container {
  width: 100%;
  margin: auto;
  padding-top: 32px;
  position: relative;
}

.content {
  margin: 24px 56px 32px;
  align-items: center;
}

.customButton {
  width: 240px;
}

.cancelButtonText {
  color: #535a6a;
}

  /*
   * Tablet resolution
   */
@media (min-width: 688px) {
  .container {
    padding-top: 64px;
  }
}
