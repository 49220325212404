.container {
  
  
  border-radius: 14px;
  background-color: white;
  z-index: 10;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  
}

.content {
 
  
  
}

.gridCentered {
  margin: auto;
}

.customButton {
  width: 256px;
}

.universal {
  display: none;
}

  /*
   * To avoid collapse the button with the div for small sizes 
   */
@media (max-width: 310px) {
  .customButton {
    width: 90%;
  }
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {
  .container {
    width: 720px;
    
  }

  .content {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

/*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {
  .container {
    
    width:960px;
  }

  .selectListItem {
    width: 304px;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .container {
    width:1075px;
  }
}

/* mobile */
@media (max-width: 767px) {
  .container {
    width:330px;
  }
  .content {
    padding-top: 16px;
    padding-bottom: 0px;
    padding-left:8px;
    padding-right:8px;
  }
}