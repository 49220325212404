.container {
  max-width: 1075px;
  width: 100%;
  margin-bottom: 15px;
}

.header {
  height: 155px;
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 35px;
  align-items: center;
}

.title {
  color: #535a6a;
}

.subtitle {
  color: #757e95;
  min-width: 350px;
}

.deviceImage {
  max-width: 32px;
}

.details {
  margin-left: 28px;
}

.settings {
  padding-left: 46px;
  padding-right: 40px;
  margin-top: 4px !important;
}

.searchTablet {
  display: none;
}

.searchTextField {
  margin-right: 4px !important;
  margin-top: -12px !important;
}

.searchMobileContainer {
  display: flex !important;
}

.accordion {
  display: block;
  margin-top: 16px;
}

.optimization {
  border-top-style: solid;
  border-top-color: #e5e5e5;
  border-top-width: 1px;
  margin-top: 34px;
  padding-top: 45px;
  display: flex;
  align-items: center;
  display: none;
}

.deviceImageMobile {
  margin-left: -16px !important;
  margin-right: 16px !important;
}

.dividerMobile {
  display: block;
}

.rebootPanel {
  margin-top: 4px !important;
}

@media (min-width: 200px) {
  .header {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (min-width: 530px) {
  .header {
    height: 155px;
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 35px;
    align-items: center;
    text-align: left;
  }
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {
  .deviceImage {
    max-width: 48px;
  }

  .details {
    margin-left: 58px;
  }

  .settings {
    margin-top: 20px !important;
  }

  .searchTablet {
    display: block;
    max-width: 404px;
    width: 404px;
    margin-left: auto;
    margin-right: 0;
  }

  .searchMobileContainer {
    display: none !important;
  }

  .accordion {
    display: none;
  }

  .optimization {
    display: flex;
  }

  .dividerMobile {
    display: none;
  }

  .rebootPanel {
    margin-top: 16px !important;
  }
}

.subtitleCount {
  color: #f1582d;
  font-size: 16px;
  font-weight: 600;
}

.filters {
  display: grid;
  grid-template-columns: max-content 0.8fr;
  align-items: center;
  justify-content: space-between;
  column-gap: 14px;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterOptions {
  height: 28px;
}

.filterLabel {
  font-size: 18px;
  color: #007dba;
  font-weight: 600;
  margin-left: 8px;
}

.search {
  border-radius: 30px !important;
  height: 44px;
}

.optimizationName {
  color: #007dba;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.optimizationDescription {
  color: #757e95;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}
