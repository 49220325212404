.root {
  justify-content: space-between;
}

.container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
  border-radius: 14px;
  border-radius: 14px;
  background-color: white;
}

.content {
  margin: 4px 16px 0px 16px;
  padding-top: 12px;
  display: flex;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.logoImage {
  height: 24px;
  width: 25px;
}

.logo {
  order: -1;
  flex: 20%;
  height: 48px;
  max-width: 120px;
}

.searchTextField {
  width: 70px;
  margin-right: 4px !important;
  margin-top: -12px !important;
}

.settingsText {
  cursor: pointer;
  font-weight: 600;
}

.actionsContainer {
  order: -1;
  flex: 25%;
  height: 48px;
  justify-content: flex-end;
  max-width: 230px;
}

.tabsCustom {
  margin-top: 4px;
  background-color: white !important;
  flex-basis: 100%;
  max-width: 100%;
  order: 0;
  flex-grow: 1;
  flex-basis: 50%;
}

.breadcrumbContainer {
  margin-top: 4px;
}

  /*
   * Tablet resolution
   */
   @media (min-width: 688px) {

    .root {
      justify-content: space-around;
    }
  
    .content {
      margin: 20px 16px 0px 16px;
      padding-top: 44px;
    }

    .logo {
      order: -1;
      height: 48px;
    }

    .actionsContainer {
      order: 1;
    }

    .tabsCustom {
      margin-top: 0px;
      max-width: 280px;
    }

    .searchTextField {
      width: 90px;
    }

    .breadcrumbContainer {
      margin-top: 20px;
    }

   }
  
  /*
   * Specific scenario of the tabs 
   */
@media (min-width: 949px) {
  .actionsContainer {
    order: 1;
  }

  .tabsCustom {
    max-width: 460px;
  }

  .searchTextField {
    width: 100px;
  }
}
