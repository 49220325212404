.deceptorProtection {
    background-color: #f5f6f9;
    padding: 26px 57px 0 68px;
    max-width: 1075px;
}

.emphasis {
    font-weight: 600;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
}

.headerImage {
    width: 120px;
    height: 88px;
}

.headerTextContainer {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 35px;
}

.headerTitle {
    color: #007dba;
}

.headerDescription {
    color: #757e95;
}

.logoImage {
    height: 24px;
    width: 25px;
}

.body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bodyTitle {
    color: #007dba;
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
    margin-top: 8px;
}

.dividerBottom {
    margin-bottom: 25px;
}

@media (max-width: 1023px) {
    .deceptorProtection {
        padding: 24px;
    }
}

@media (max-width: 767px) {
    .header {
        margin: 0;
        padding: 18px;
    }

    .headerImage {
        width: 63px;
        height: 46px;
    }

    .headerTextContainer {
        margin: 0 0 0 16px;
    }

    .body {
        margin: 0;
    }

    .deceptorProtection {
        padding: 0;
    }

    .deceptorEntryContainer {
        flex-basis: 100%;
    }
}
