.fileCleaningHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 38px 45px 54px; 
}

.headerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 500px;
}

.gearsImage {
    width: 120px;
    height: 80px;   
}

.gearsImageContainer .headerTitle {
    display: none;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cleanupButton {
    width: 245px;
    height: 44px;
    padding: 0;
    margin-top: 20px;
    font-size: 16px;
    line-height: 17px;
}

.rescanButton {
    width: 245px;
    height: 44px;
    padding: 0;
    font-size: 16px;
    line-height: 17px;
}

.graphAndText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px 45px;
}

.freedSpaceGraph {
    flex-grow: 0;
}

.fileCleaningBodyText {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(242, 242, 242, 0.38);
    border-radius: 12px;
    padding: 20px 15px;
}

.bodyGraphTextSpacer {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 25px;
}

.bodySummary {
    color: #535A6A;
    max-width: 380px;
}

.controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-around;
    border-top: 1px solid #D2D2D2;
    padding-top: 20px;
}

.controlContainer {
    flex-basis: 33%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.controlContainer .controlLabel {
    color: #757E95;
    margin-right: 10px;
}

.highlight {
    color: #F1582D;
}

.highlightGreen {
    color: #0FAC21;
}

.resultsMessageAndButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.resultsMessageAndButtonContainer {
    margin-top: 20px;
    margin-bottom: 10px;
}

.lastCleanedMessageContainer {
    margin-right: 20px;
}

.mobileResultsContainer {
    display: none;
}

.checkMark {
    margin-right: 5px;
}

@media (max-width: 1199px) {
    .headerText {
        max-width: 500px;
    }
}

@media (max-width: 1023px) {
    .fileCleaningHeader {
        flex-wrap: wrap;
    }

    .rescanButtonContainer {
        flex-basis: 100%;
        margin-top: 15px;
    }

    .graphAndText {
        flex-direction: column;
    }

    .bodySummary {
        margin-top: 57px;
    }

    .controlContainer {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .rescanButtonContainer {
        flex-basis: 100%;
        margin-top: 15px;
    }

    .controlLabel {
        margin-bottom: 15px;
    }

    .buttonContainer {
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

    .cleanupButton {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .fileCleaning .fileCleaningBody {
        margin-left: 20px;
        margin-right: 20px;
    }

    .fileCleaning .fileCleaningHeader {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 20px;
        margin-top: 5px;
    }

    .gearsImage {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .gearsImageContainer .headerTitle {
        display: inline;
        vertical-align: top;
    }

    .headerText {
        text-align: center;
    }

    .headerText .headerTitle {
        display: none;
        vertical-align: top;
    }

    .headerDescription {
        font-size: 16px;
        line-height: 22px;
    }

    .buttonContainer {
        margin-top: 20px;
    }

    .bodySummary {
        margin-top: 0;
    }

    .fileCleaningBodyText {
        text-align: center;
        margin-top: 43px;
    }

    .bodyGraphTextSpacer {
        flex-basis: 0px;
    }

    .controlContainer {
        flex-direction: row;
        flex-basis: 100%;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #E6E6E6;
    }

    .controlContainer:last-of-type {
        border-bottom: none;
    }

    .controlSwitch span {
        font-size: 14px;
    }

    .cleanupButton {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .resultsMessageAndButtonContainer {
        display: none;
        flex-direction: column;
    }

    .mobileResultsContainer {
        display: flex;
    }

    .mobileResultsContainer .viewResultsButton {
        margin-top: 10px;
    }
}