.deceptorDetails {
    position: relative;
    width: 100%;
    padding: 22px 30px;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
}

.closeButton {
    position: absolute;
    top: 27px;
    right: 19px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 40px;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.deceptorImage {
    width: 43px;
    height: 43px;
    margin-right: 27px;
}

.titleTextContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.title {
    color: #007dba;
}

.filePathContainer {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: start;
    word-wrap: break-word;
}

.filePathContainer .filePathLabel {
    font-weight: 600;
    margin-right: 0.5em;
    white-space: nowrap;
}

.buttonContainer {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin-right: 45px;
}

.actionButton {
    width: 252px;
    height: 44px;
    margin-bottom: 12px;
}

.appEsteemInfoContainer {
    position: relative;
    align-self: center;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 222px;
}

.appEsteemImage {
    width: 96px;
    height: 21px;
}

.appEsteemHoverContainer {
    position: absolute;
    width: 631px;
    top: 99%;
    left: -431px;
    box-shadow: 0px 4px 12px #00000026;
    border: 1px solid #00000000;
    background: #ffffff;
    border-radius: 14px;
    padding: 21px 31px;
}

.appEsteemHoverName {
    font-weight: 600;
    margin-right: 0.25rem;
}

.infoImage {
    width: 14px;
    height: 14px;
    margin-right: 14px;
}

.avListContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.avListContainer .blockedBy {
    color: #007dba;
    font-weight: 600;
    text-align: start;
}

.avList {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-inline-start: 0;
}

.avListItem {
    flex-basis: 25%;
    text-align: start;
    margin-bottom: 5px;
}

.mobileFilePathContainer {
    display: none;
}

@media (max-width: 1023px) {
    .header {
        flex-direction: column;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .buttonContainer {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 25px;
        margin-right: 0;
        width: 100%;
    }

    .appEsteemHoverContainer {
        left: -381px;
    }

    .avListItem {
        flex-basis: 33.33333%;
    }
}


@media (max-width: 767px) {
    .deceptorDetails {
        padding: 0;
    }

    .closeButton {
        top: 0;
    }

    .deceptorImage {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .appEsteemHoverContainer {
        width: 300px;
        left: -40px;
    }

    .avListItem {
        flex-basis: 100%;
    }

    .buttonContainer {
        flex-direction: column;
        align-items: center;
    }

    .filePathContainer {
        display: none;
    }

    .filePathValue {
        word-wrap: break-word;
    }

    .mobileFilePathContainer {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        text-align: start;
        word-wrap: break-word;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        padding: 8px 0;
        margin-top: 20px;
    }
    
    .mobileFilePathLabel {
        font-weight: 600;
        color: #007DBA;
    }
}