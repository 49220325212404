.container {
  border-radius: 14px;
  height: 680px;
  background-color: white;
  outline: 0;
}

.content {
  margin: 24px 16px 56px 16px;
}

.titleIcon {
  width: 26.75px;
  height: 18.62px;
}

.cardText {
  display: none;
  margin-left: 8px;
  margin-right: 4px;
}

.creditCardIcon {
  width: 32px;
  height: 21px;
  margin-left: 4px;
}

.PayPalIcon {
  margin-left: 8px;
  width: 71px;
  height: 23px;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.closeIconContainer {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  min-width: 50px;
  min-height: 50px;
}

.customButton {
  width: 278px;
  outline: none;
  height: 40px;
}

.textMetric {
  word-wrap: break-word;
}

  /*
   * Tablet resolution
   */
   @media (min-width: 688px) {
    .content {
      margin: 24px 96px 56px 96px;
    }

    .metricIcon {
      margin-left: 0px;
    }

    .cardText {
      display: inline;
    }
    
    .closeIcon {
      left: 64px;
    }

  }