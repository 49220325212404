.container {
  border-radius: 14px;
  background-color: white;
  outline: 0;
  padding-top: 20px;
  width: 90%;
  max-width: 1064px;
}

.content {
  width: 90%;
  margin: 24px 16px 56px 16px;
}

.titleIcon {
  width: 26px;
  height: 26px;
}

.cardText {
  display: none;
  margin-left: 8px;
  margin-right: 4px;
}

.creditCardIcon {
  width: 32px;
  height: 21px;
  margin-left: 4px;
}

.PayPalIcon {
  margin-left: 8px;
  width: 71px;
  height: 23px;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.closeIconContainer {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  min-width: 50px;
  min-height: 50px;
}

.customButton {
  width: 278px;
  outline: none;
  height: 40px;
}

.textMetric {
  word-wrap: break-word;
}

.divider {
  width: 100%;
}

  /*
   * Tablet resolution
   */
   @media (min-width: 688px) {
    .content {
      margin: 24px 96px 56px 96px;
      margin: auto;
    }

    .divider {
      width: 85%;
      margin: auto;
      margin-top: 16px;
    }

    .titleEditPayment {
      padding: 0px 72px;
    }

    .metricIcon {
      margin-left: 0px;
    }

    .cardText {
      display: inline;
    }
    
    .closeIcon {
      left: 64px;
    }

  }

  /*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {
  .titleEditPayment {
    padding: 0px;
  }

  .divider {
    width: 100%;
  }

  .titleEditPayment {
    padding: 0px 44px;
  }
}

  /*
   * Desktop
   */
   @media (min-width: 1200px) {
    .titleEditPayment {
      padding: 0px;
    }
  
    .titleEditPayment {
      padding: 0px;
    }
  }