.field {
  width: 100%;
}

.cvvIcon {
  width: 74.82px;
  height: 48.92px;
  margin-left: 16px;
}

.importantText {
  color: "#BE2222";
}

.fieldContainer {
  flex-basis: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.responsiveBox {
  flex-basis: 100%;
  margin-top: 16px;
}

.buttonContainer {
  margin-top: 24px;
  text-align: "center";
}

.zipCodeField {
  flex-basis: 100%;
  margin-top: 24px;
}

.creditCardInfo {
  display: flex;
  flex-wrap: wrap;
}

.cardInfoField {
  flex-basis: 100%;
}

.dateField {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.selectField {
  min-width: 92px;
}

.cvvContainer {
  display: flex;
  justify-content: flex-end;
}

.dateContainer {
  flex-basis: 100%;
  margin-top: 8px;
}

/*
   * Tablet resolution
   */
@media (min-width: 688px) {

  .dateContainer {
    flex-basis: 50%;
    margin-top: 4px;
  }

  .responsiveField {
    max-width: 608px;
  }

  .form {
    padding: 0px 72px;
  }

  .zipCodeField {
    flex-basis: 30%;
    margin-top: 32px;
  }
  
  .dateField {
    flex-basis: 70%;
    flex-wrap: nowrap;
  }

  .cvvContainer {
    justify-content: flex-start;
  }
}

/*
   * Tablet horizontal resolution Laptop resolution  
   */
@media (min-width: 1024px) {

  .form {
    padding: 0px 44px;
  }

  .fieldContainer {
    flex-basis: 50%;
  }

  .creditCardInfo {
    max-width: 384px;
  }

  .responsiveField {
    max-width: 384px;
  }

  .buttonContainer {
    margin-top: 48px;
    text-align: "end";
  }

  .zipCodeField {
    flex-basis: 100%;
    margin-top: 24px;
  }

  .dateField {
    flex-basis: 100%;
  }

  .cvvContainer {
    justify-content: flex-end;
  }
}

  /*
   * Desktop
   */
   @media (min-width: 1200px) {

    .form {
      padding: 0px;
    }
  
    .fieldContainer {
      flex-basis: 50%;
    }
  
    .creditCardInfo {
      max-width: 448px;
    }
  
    .responsiveField {
      max-width: 448px;
    }
  
    .buttonContainer {
      margin-top: 48px;
      text-align: "end";
    }
  }
